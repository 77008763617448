$(document).ready(function () {

    // Disable before submit
    $(window).on('submit', function () {
        $(".btn-submit").prop('disabled', true);
        $(".btn-submit").html('Please wait for a second')
    });

     // Show Modal
    var myModal = new bootstrap.Modal(document.getElementById('alert'));
    myModal.show();
 });